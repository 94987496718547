@import 'assets/styles/_base.scss';

// TODO: temporal
.app__content{
  padding-left: 0;
  padding-right: 0;
}

.error_message{
  &--red {
    color: $red !important;
    text-align: start;
  }
}
button:disabled{
  opacity: 0.65 !important;
}
.disable {
  opacity: 0.4;
}

.only-Desktop {
  display: none;
  @include desktop {
    display: block;
  }
  @include tablet-large {
    display: block;
  }
}

.only-mobile {
  cursor: pointer;
  @include desktop {
    display: none;
  }
  @include tablet-large {
    display: none;
  }
}
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ACB4B9;
  outline: none;
  margin-right: 5px;
}

input[type="radio"]:checked {
  border: 2px solid #2FAD6F;
}

input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 50%;
  background: #2FAD6F;
}

.global{
  &__ErrorInput{
    color: #D92D20;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: start;
  }
  &__input__text{
    color: red;
  }
  &__center {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__btnWhite{
    width: 80% !important;
    margin-left: 40px !important;
    color: #2FAD6F !important;
    background-color: #E2F7ED !important;
    margin-bottom: 20px !important;
    @include desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }
    &:hover {
      color: #2FAD6F !important;
      background-color: #E2F7ED !important;
      box-shadow: 0 4px 10px rgba(20, 43, 127, 0.25);
    }
  }
  &__btnGreen{
    width: 80% !important;
    margin-left: 40px !important;
    @include desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }
    &:hover {
      background: #2FAD6F !important;
      color: #E2F7ED !important;
      box-shadow: 0 4px 10px rgba(20, 43, 127, 0.25);
    }
  }
}

.fake_metamap__button {
  width: 212.875px;
  max-width: 212.875px;
  height: 43px;
  max-height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(80, 93, 237);
  border-radius: 4px;
  cursor: not-allowed;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 17.25px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0;
  margin-bottom: 0 !important;
  opacity: 0.5;

  & figure {
    margin: 0;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    & img {
      width: 24px !important;
    }
  }

  & main {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    & label {
      font-family: sans-serif;
      cursor: not-allowed;
    }
  }
}
